// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-buttons {
    position: fixed;
    left: 25%;
    bottom: 50px;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    gap: 10px; /* Espacio entre los botones */
    z-index: 1000; /* Asegura que esté sobre otros elementos */
}

.bottom-button {
    background-color: #DA291C; /* Color de fondo rojo */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/columna.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,YAAY;IACZ,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;IACvB,SAAS,EAAE,8BAA8B;IACzC,aAAa,EAAE,2CAA2C;AAC9D;;AAEA;IACI,yBAAyB,EAAE,wBAAwB;IACnD,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".bottom-buttons {\n    position: fixed;\n    left: 25%;\n    bottom: 50px;\n    transform: translateX(-50%);\n    display: flex;\n    justify-content: center;\n    gap: 10px; /* Espacio entre los botones */\n    z-index: 1000; /* Asegura que esté sobre otros elementos */\n}\n\n.bottom-button {\n    background-color: #DA291C; /* Color de fondo rojo */\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    font-size: 16px;\n    border-radius: 5px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
